import styled from 'styled-components'
import { Link } from 'gatsby'
import Colors from '../themes/Colors'

export const Content = styled.div`
  display: block;
  width: 100%;
  max-width: 780px;
  margin: auto;

  @media screen and (min-width: 1400px) {
    max-width: 960px;
  }
`

export const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: auto;

  img {
    max-width: 100%;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1200px;
  }
`

export const TitleBar = styled.div`
  max-width: 620px;
  width: 100%;
  margin: auto;
  padding: 0 16px;

  h1 {
    margin: 0;
    letter-spacing: -4px;
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 12px;
    letter-spacing: -2px;
  }

  h3 {
    font-weight: 500;
    width: 100%;
    margin: 0;
    letter-spacing: -1.14px;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 30px;
  }

  p {
    color: #bfbfbf;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 600px) {
    max-width: 620px;
    width: 80%;
    margin: auto;
    padding: 0px;

    h1 {
      margin: 0;
      letter-spacing: -4px;
      font-size: 42px;
      line-height: 44px;
      margin-bottom: 12px;
      letter-spacing: -2px;
    }

    h3 {
      font-weight: 500;
      width: 80%;
      margin: 0;
      letter-spacing: -1.14px;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 1400px) {
    max-width: 960px;

    h1 {
      margin: 0;
      letter-spacing: -4px;
      font-size: 48px;
      line-height: 52px;
      margin-bottom: 12px;
      letter-spacing: -4px;
    }

    h3 {
      font-weight: 500;
      width: 100%;
      margin: 0;
      letter-spacing: -1.14px;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 30px;
    }

    p {
      color: #bfbfbf;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 30px;
    }
  }
`

export const ShareButton = styled.a`
  color: white;
  border-radius: 2px;
  display: inline-flex;
  font-size: 14px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  padding: 8px 12px;
  line-height: 18px;
  margin-right: 12px;
  width: 140px;
  justify-content: space-between;
  background-color: ${props => (props.twitter ? '#4DB6EE' : '#3965A0')};
  margin-bottom: 20px;

  img {
    height: 18px;
  }
`

export const Article = styled.div`
  max-width: 620px;
  width: 80%;
  margin: auto;
  margin-top: 30px;
  padding: 0px;

  * {
    max-width: 453px;
    margin: 0;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 2em;
  }

  p:first-of-type {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 38px;
  }

  p:first-of-type::first-letter {
    color: ${Colors.red};
    float: left;
    font-size: 60px;
    font-weight: 900;
    line-height: 52px;
    padding-top: 0px;
    padding-right: 8px;
    padding-left: 3px;
  }

  h2 {
    font-size: 28px;
    line-height: 32px;
    margin-top: 22px;
    margin-bottom: 32px;
  }

  h3 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  a {
    color: #ff0053;
    text-decoration: underline;
  }

  img {
    display: block;
    width: 100%;
    object-fit: cover;
    margin: 50px 0px 60px 20px;
  }

  @media screen and (min-width: 600px) {
    img {
      display: block;
      width: 120%;
      max-width: 540px;
      margin: 30px 0 30px 0;
      border-radius: 6px;
      overflow: hidden;
      object-fit: cover;
      box-shadow: 0 8px 22px 0 rgba(39, 41, 43, 0.3);
    }
  }

  @media screen and (min-width: 1400px) {
    * {
      max-width: 960px;
    }

    p {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 2em;
    }

    p:first-of-type {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 38px;
    }

    p:first-of-type::first-letter {
      color: ${Colors.red};
      float: left;
      font-size: 72px;
      font-weight: 900;
      line-height: 64px;
      padding-top: 0px;
      padding-right: 8px;
      padding-left: 3px;
    }

    h2 {
      font-size: 36px;
      line-height: 36px;
      margin-top: 22px;
      margin-bottom: 32px;
    }

    h3 {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 12px;
    }
    img {
      width: 180%;
      max-width: 720px;
      margin: 30px 0 30px 0px;
      border-radius: 6px;
      overflow: hidden;
      object-fit: cover;
      box-shadow: 0 8px 22px 0 rgba(39, 41, 43, 0.3);
    }
  }
`

export const Back = styled(Link)`
  display: block;
  margin: 20px 16px 0px 20px;
  color: ${Colors.red};
  font-size: 22px;

  span {
    font-size: 32px;
  }
`
