import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Spacer from '../components/Spacer'
import RelatedArticles from '../components/RelatedArticles'
import Metrics from '../themes/Metrics'
import readingTime from 'reading-time'
import {
  Content,
  Wrapper,
  TitleBar,
  ShareButton,
  Article,
} from '../components/BlogPostStyles'

import facebookIcon from '../images/social-facebook.svg'
import twitterIcon from '../images/social-twitter.svg'

const blogPost = ({ pageContext, data }) => {
  const {
    title,
    tags,
    subtitle,
    article,
    heroImage,
    slug,
    relatedArticles,
  } = pageContext
  const path = `https://berightback.travel/articles/${slug}`
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${path}`
  const twUrl = `https://twitter.com/share?text=${title} by @travelbrb&url=${path}`
  const readTime = readingTime(article)

  return (
    <Layout>
      <Wrapper>
        <Helmet>
          <title>{title}</title>
          <meta keywords={tags} />
          <meta description={subtitle} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@travelbrb" />
          <meta name="twitter:creator" content="@travelbrb" />
          <meta property="og:title" content={title} />
          <meta property="og:type" content="article" />
          <meta property="og:description" content={subtitle} />
          <meta property="og:url" content={path} />
          <meta property="og:image" content={'https:' + heroImage.file.url} />
        </Helmet>
        <Content>
          <Spacer height={Metrics.bigSpacer} />
          <TitleBar>
            <h1>{title}</h1>
            <h3>{subtitle}</h3>
            <ShareButton href={fbUrl} target="_blank" rel="noopen noreferrer">
              <span>Share</span>
              <img src={facebookIcon} alt="Facebook logo" />
            </ShareButton>
            <ShareButton
              href={twUrl}
              target="_blank"
              rel="noopen noreferrer"
              twitter
            >
              <span>Tweet</span>
              <img src={twitterIcon} alt="Twitter logo" />
            </ShareButton>
            <p>{readTime.text}</p>
          </TitleBar>
          <img src={`${heroImage.file.url}?&w=800`} alt={heroImage.description} />
          <Article
            dangerouslySetInnerHTML={{
              __html: article,
            }}
          />
        </Content>
        <Spacer height={Metrics.bigSpacer} />
      </Wrapper>
      {relatedArticles.length > 1 && <RelatedArticles articles={relatedArticles} />}
    </Layout>
  )
}

export default blogPost
